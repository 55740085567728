<template>
  <div style="height:100%;">
      <div class="home"  >
        <heardNav @msg="getData" :pageNum="pageNum"></heardNav>
        <div class="index" ><concent @pupopStatus="getPupopStatus"/></div>
     
        <!-- <div class="mask" v-if="showPopup" @click="hidePopup"></div> -->
        <foot/>
      </div>

   

      
  </div>
</template>


<script>
// @ is an alias to /src
import heardNav from '@/components/heardNav/index.vue'
import concent from '@/components/index/index.vue'
import foot from '@/components/foot/index'


export default {
  name: 'Home',
  components: {
    heardNav,
    concent,
    foot,
  },
  data(){
        return{
          run:true,
          index:'index',
          product:'product',
          showSelect:false,
          cheickWheel:"component-fade",
          pageNum:1,
          showPopup:false,
          showQrCodePopup:true,
          showMobile:true
        }
  },
  methods:{
   
    getData(msg){
        console.log(msg)
    },
    popupStatus(Status){

      this.showPopup=Status
    },
    hidePopup(){
      this.showPopup=false
    },
    showQrCode(){
      this.showQrCodePopup=!this.showQrCodePopup
    },
    getPupopStatus(event){
      // console.log(event)
      this.showQrCodePopup=event
    },

    
  },
    mounted() {
      var that = this;
      const baseSize = 16; // 32
      function setRem() {
      // console.log(window.screen.width)
      let clientWidths= document.documentElement.clientWidth
      // console.log(clientWidths)
      if(clientWidths<1300&&clientWidths>1000){
        clientWidths=1300
        that.showMobile=true
        const scale = clientWidths / 1920; // 750
        document.documentElement.style.fontSize =baseSize * Math.min(scale, 1) + "px"; 
        }
        if(clientWidths<990){
          that.showMobile=false
          const scale = clientWidths / 375; // 750
          document.documentElement.style.fontSize =baseSize * Math.min(scale, 0.21) + "px";
        }
        if(clientWidths>1300){
          that.showMobile=true
          const scale = clientWidths / 1920; // 750
          document.documentElement.style.fontSize =baseSize * Math.min(scale, 1) + "px";
        }
        }
        // <!--把window.onresize事件挂在到mounted函数上-->
        window.onresize = () => {
          console.log('_________________')
            setRem();
        };
        setRem();

        if(this.$route.params.type){
          this.pageNum=this.$route.params.type
          console.log(this.pageNum)
        }
    },


}
</script>
<style lang="stylus">

</style>