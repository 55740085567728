<template>
  <div class="main">
    <heardNav  :pageNum="4"></heardNav>
    <div class="swiper">
  <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide">
          <div class="showBanner">
            <a href="https://mp.weixin.qq.com/s?__biz=MzkxODUwMjAyMw==&mid=2247483763&idx=1&sn=eadc722fe0789ffcd58b8e840ad3b5e0&chksm=c1b12e9bf6c6a78de0040f77560d2d8c9113d7f63500e414dcc2292cbd8d93e40164fa23b94a#rd"
             target="view_frame"><img src="https://jimte.oss-cn-hangzhou.aliyuncs.com/static/new1.webp" alt="" srcset=""></a> </div>
        </div>
    
      </div>
    </div>

<!-- show______news_________list  -->
<div class="news_list">

  <div class="news_item" v-for="(item,index) in news_list" :key="index">
    <a :href="item.news_url" target="view_frame">
      <div class="news_item_info">
        <div class="news_image"><img :src="item.showUrl" alt=""></div>
        <div class="new_text">
            <div class="new_title">{{ item.title }}</div>
            <div class="new_desc">{{ item.dec }}</div>
            <div class="news_time_icon">
              <div class="new_icon"><img src="../../assets/newsIcon.png" alt=""></div>
              <div class="news_time">{{ '2024-07-0'+ (9-index) }}</div>
            </div>
        </div>
      </div>

    </a>

  </div>

</div>


<foot />
  </div>
</template>

<script>
import heardNav from '@/components/heardNav/index.vue'
import foot from '@/components/foot/index'
export default {
  components: {
    heardNav,
    foot
    },
  data(){
        return{
          news_list:[
            {
              title:"文化和旅游部汛期、暑期出游提示",
              dec:"我国全面进入主汛期，极端天气多发，同时暑期临近，臻心生活平台提醒广大游客",
              showUrl:"https://jimte.oss-cn-hangzhou.aliyuncs.com/static/new_2.png",
              news_url:'https://mp.weixin.qq.com/s?__biz=MzkxODUwMjAyMw==&mid=2247483785&idx=1&sn=9d02c4fdd72f878d0b5414d1257c547f&chksm=c1b12e61f6c6a77722ab016647046b975c35e78acf202782460a3bd4f2ca45545059a2238f05#rd'
            },
            {
              title:'百名欧洲国家旅行商来华“踩线”活动顺利开展',
              dec:"5月26日至6月8日，由驻巴黎、马德里旅游办事处邀请的39名法国、西班牙旅行商赴重庆、陕西、四川三地踩线。",
              showUrl:"https://jimte.oss-cn-hangzhou.aliyuncs.com/static/new_3.png",
              news_url:'https://mp.weixin.qq.com/s?__biz=MzkxODUwMjAyMw==&mid=2247483794&idx=1&sn=ce2ddd8437e0075046d76b9065fce078&chksm=c1b12e7af6c6a76ce5a31c280ed496714d6165ec3b8116f6a46b08859d41cd08edad8504342a#rd'
            },
            {
              title:"探索虚拟现实全景文旅的未来",
              dec:"全面介绍”臻心生活“的文旅+VR，所带来的沉浸式体验。",
              showUrl:"https://jimte.oss-cn-hangzhou.aliyuncs.com/static/new_4.png",
              news_url:'https://mp.weixin.qq.com/s?__biz=MzkxODUwMjAyMw==&mid=2247483806&idx=1&sn=79557a9233a7c7277ed709da2deea676&chksm=c1b12e76f6c6a7600df8361cb9e6fbe777c58e35e0b6b05e2141eceef0539cb70df24bbc9b87#rd'
            },
            {
              title:"未来旅游的新体验：臻心生活推出的VR全景文旅",
              dec:"随着科技的迅猛发展，旅游方式正在经历一场革命性的转变。",
              showUrl:"https://jimte.oss-cn-hangzhou.aliyuncs.com/static/new_5.png",
              news_url:'https://mp.weixin.qq.com/s?__biz=MzkxODUwMjAyMw==&mid=2247483816&idx=1&sn=19bd9bc7f6d9c1bf78ed8f2e0ec3f46e&chksm=c1b12e40f6c6a756f4f922d1584c8c3edeee20b89baaf31e47267157f9f6c84990826f6b1b7d#rd'
            },
          ],


        }
  },
  
  methods:{
    setSwiper(){


    },
    handleMouseover(e){
      let that=this
      for (const key in this.decList) {
        that.decList[key].active=false
      }
      that.decList[e].active=true
    },
    // 按钮移入
    handleMouseoverBtn(e){  
      console.log(e)
      let that=this
      for (const key in this.decList) {
        that.decList[key].showActive=true
      }
      that.decList[e].showActive=false
      
    },
    // 按钮移出
    handleMousleave(e){
      console.log(e,'chu')
      this.decList[e].showActive=true
      
    },
   
  },
  mounted() {
     this.setSwiper()
     
    },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.swiper
  width 100%
  img
    width 100%

.product
  .product_concent
    display flex
    justify-content space-between
    padding-top 54px
    .product_img
      width 630px
      img
        width 100%
.news_item
  padding-top 38px
.news_list
  width 1400px
  margin 0 auto
  padding-bottom 600px
.news_item_info
  display flex
  .news_image
    width 360px
    height 180px
    img
      width 100%
  .new_text
    text-align left
    padding-left 38px
    flex 1
    position relative
    .new_title
      font-size 32px
      font-weight 400
      color #333333
    .new_desc
      font-size 20px
      color #666666
      padding 20px 0
    .new_icon
      width 24px
      height 24px
      img
        width 100%
    .news_time_icon
      width 100%
      display flex
      justify-content space-between
      position absolute
      width 100%
      bottom 0
      color #666666
      font-size 16px
</style>
