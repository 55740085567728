<template>
    <div class="content" style="width: 100%;height: 100%;">
        <iframe 
        :src="vrUrl" 
        width="100%"
        height="100%"
        andbox="allow-scripts allow-same-origin allow-popups"
        frameborder="0"></iframe>
    </div>
  </template>
  
  
  <script>
  // // @ is an alias to /src
  // import concent from '@/components/index.vue'
  // import product from '@/components/product.vue'
  
  
  export default {
  
    data(){
        return{
          run:true,
          vrUrl:"",
          num:0
          
        }
    },
    methods:{
  
    },
    mounted() {
      var that = this;
      console.log(!this.$route.params.type,localStorage.getItem("num"))
      if(!this.$route.params.type){
        if(localStorage.getItem("num")!=undefined){
          this.$route.params.type=localStorage.getItem("num")
        }else{
          this.$route.params.type=1
        }
        
      }
      if(this.$route.params.type==1){
        this.vrUrl="https://www.kuleiman.com/176643/index.html"
      }
      if(this.$route.params.type==2){
        this.vrUrl="https://www.kuleiman.com/176641/index.html"
      }
      if(this.$route.params.type==3){
        this.vrUrl="https://www.kuleiman.com/176642/index.html"
      }
    },
  
  }
  </script>
  <style scoped lang="stylus">
  </style>