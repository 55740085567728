<template>
  <div class="main_max">
    <div class="main" >
        <div class="LogoName" @click="selectActiver(1)">
              <div class="logo"><img src="../../assets/logo.png" alt=""></div>
              <div class="name">
                吉美特集团  JIMEITE
              </div>
        </div>
        <div class="rightBtn">
            <div class="navBtn" :class="pageNum==1?'activer':''" @click="selectActiver(1)">首页</div>
            <div class="navBtn" :class="pageNum==2?'activer':''" @click="selectActiver(2)">平台介绍</div>
            <div class="navBtn" style="min-width:6.6rem;" :class="pageNum==3?'activer':''" @click="selectActiver(3)">VR专区</div>
            <div class="navBtn" :class="pageNum==4?'activer':''" @click="selectActiver(4)">新闻中心</div>
            <div class="navBtn downloadBtn" @click="showApp=!showApp" style="margin-right: 0;">
              <div> 下载</div>
              <div class="showicon">
                <img v-if="!showApp" src="../../assets/xiala.png" alt="">
                <img v-if="showApp" style="height: 100%;" src="../../assets/youb.png" alt="">
              </div>
            </div>
        </div>
    </div>
    <div  class="showQrcode" v-if="showApp" @mouseleave="handleMousleave()">
      <div class="qrCode">
        <div class="qrCodeBg_icon"></div>
        <div class="qrCodeBg"> 
          <div class="qr_codes"><img src="https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/13/N2FdhBp5Rt.jpg" alt=""></div>
          <div class="qr_text">臻心生活APP</div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>


export default {
  name: 'Home',
  props: {
    pageNum:{
      type:Number
    }
  },
  components: {
    
  },
  data(){
      return{
        run:true,
        showActiver:1,
        showApp:false
      }
  },
  methods:{
    selectActiver(num){
      let that=this
      if(num==1){
        this.$router.push({ name: 'Home', });
      }
      if(num==2){
        this.$router.push({ name: 'product', });
      }
      if(num==3){
        this.$router.push({ name: 'vr', });
      }
      if(num==4){
        this.$router.push({ name: 'news', });
      }
      // that.showActiver=num
      // this.$emit('msg',that.showActiver)
    },
    handleMousleave(e){
      this.showApp=false
    }
  },
  mounted() {
      var that = this;
      const baseSize = 16; // 32
      function setRem() {
      // console.log(window.screen.width)
      let clientWidths= document.documentElement.clientWidth
      // console.log(clientWidths)
      if(clientWidths<1300&&clientWidths>1000){
        clientWidths=1300
        that.showMobile=true
        const scale = clientWidths / 1920; // 750
        document.documentElement.style.fontSize =baseSize * Math.min(scale, 1) + "px"; 
        }
        if(clientWidths<990){
          that.showMobile=false
          const scale = clientWidths / 375; // 750
          document.documentElement.style.fontSize =baseSize * Math.min(scale, 0.21) + "px";
        }
        if(clientWidths>1300){
          that.showMobile=true
          const scale = clientWidths / 1920; // 750
          document.documentElement.style.fontSize =baseSize * Math.min(scale, 1) + "px";
        }
        }
        // <!--把window.onresize事件挂在到mounted函数上-->
        window.onresize = () => {
          console.log('_________________')
            setRem();
        };
        setRem();

    },

}
</script>
<style scoped lang="stylus">
.LogoName
  cursor pointer
  padding-left  152px
.main
    width 100%
    min-width 1300px
    position: fixed
    top 0
    z-index 20
    display flex
    justify-content space-between
    // background rgba(255, 255, 255,0.4)
    background: rgba(0, 0, 0, 0.10);
    backdrop-filter blur(10px)  
    --webkite-backdrop-filter blur(10px)  
    box-sizing border-box
    box-shadow: 0px 2px 10px 0px rgba(0,96,204,0.1);
    height 80px
    line-height 80px
.name
    font-size: 22px;
    font-weight: 500;
    color: #fff;
.logo
    width: 42px;
    margin-right 11px
    padding 19px 0
    img
        width   100%
        height  100%

.rightBtn
    align-items center
    font-size   18px
    padding-right  152px

.navBtn
    position relative
    text-align center
    cursor pointer
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    margin-right 38px
    height 100%

.downloadBtn
  display flex
  justify-content center
  align-items center
  .showicon
    width 20px
    height 18px
    line-height 30px
    margin-left 4px
    position relative
    top -4px
    img
      width 100%
      
.activer
  font-weight: 600

.activer::after
    content '' 
    display inline-block
    width 100%
    height 2px
    position absolute
    bottom -1px
    background  #fff
    border-radius 2px
    box-sizing  border-box
    left  0
.showQrcode 
    position fixed
    top 60px
    right 114px
  
    z-index 99
  .qrCode
    
    .qrCodeBg_icon
      width 0 
      height 0
      border-top 20px solid rgba(0,0,0,0)
      border-bottom 10px solid #fff
      border-left 20px solid rgba(0,0,0,0)
      border-right 20px solid rgba(0,0,0,0)
      position relative
      right -120px
      bottom -2px
    .qrCodeBg
      width 200px
      padding 10px
      background #fff
      border-radius 4px
      box-sizing border-box
      img
        width 100%
    .qr_text
        font-size 16px
        color #748EA6
</style>