<template>
    <div class="content" style="width: 100%;height: 100%;">
   
    </div>
  </template>
  
  
  <script>

  
  export default {
  
    data(){
        return{
          run:true,
          vrUrl:"",
          num:0
          
        }
    },
    methods:{
  
    },
    mounted() {
        console.log(this.$route.query.url)
        localStorage.setItem("url",this.$route.query.url)
        localStorage.setItem("title",this.$route.query.title ? this.$route.query.title:'吉美特')
        this.$router.push({ name: 'productVr', });
    },
  
  }
  </script>
  <style scoped lang="stylus">
  </style>