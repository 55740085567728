import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import m_product from '../components/m-product/index.vue'
import m_video from '../components/m-video/index.vue'
import m_videoInfo from '../components/m-video/indexTwo.vue'
import m_join from '../components/m-join/index.vue'
import m_aboutus from '../components/m-aboutus/index.vue'
import news from '../components/news/index.vue'
import iframe from '../components/iframe/index.vue'
import product from '../components/product/index.vue'
import vr from '../components/videoVr/index.vue'
import productVr from '../components/vr.vue'
import vrParam from '../components/vrList.vue'


Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },{
    path: '/m_product',
    name: 'm_product',
    component: m_product
  },{
    path: '/m_video',
    name: 'm_video',
    component: m_video
  },{
    path: '/m_videoInfo',
    name: 'm_videoInfo',
    component: m_videoInfo
  },{
    path: '/m_join',
    name: 'm_join',
    component: m_join
  },{
    path: '/m_aboutus',
    name: 'm_aboutus',
    component: m_aboutus
  },{
    path: '/news',
    name: 'news',
    component: news
  },{
    path: '/vrInfo',
    name: 'vrInfo',
    component: iframe
  },
  {
    path: '/app',
    name: 'product',
    component: product
  },
  {
    path: '/vr',
    name: 'vr',
    component: vr
  },{
    path: '/productVr',
    name: 'productVr',
    component: productVr
  },{
    path: '/vrParam',
    name: 'vrParam',
    component: vrParam
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach(() => {
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	document.scrollingElement.scrollTop = 0
})
export default router
