<template>
  <div class="product_main">
    <heardNav  :pageNum="3"></heardNav>
    <div class="banner">
      <div class="bannerVideo">
        <div class="bannertitle">
          <div>全景VR</div>
            <div> 身临其境，探索全新文旅世界</div>
        </div>
        <div>
         
        </div>
      </div>
      <div class="showVideo"> 
        <video id="audioPlayer" poster="https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/13/b2sWBijbFT.jpg"
          src=""
          autoplay="autoplay" 
          muted
          loop="loop"
          ref="audioPlayer"
            >
          <source src="movie.mp4" type="video/mp4">
          <source src="movie.ogg" type="video/ogg">
        </video> 
      </div>
      <div class="mask_bg"></div>
    
    </div>

    <div class="productInfo first" style="height: auto;">
      <div class="product_info_title">
          <div class="product_info_titles">VR实景案例</div>
          <div class="product_info_title_en">VR REALITY CASE</div>
      </div>
      <div class="case_list">
        <div class="case_item" @click="seeVr(1)">
          <div class="case_image"><img src="https://jimte.oss-cn-hangzhou.aliyuncs.com/static/vr_example_1_7_20.gif" alt=""></div>
          <div class="case_title">杭州西湖</div>
        </div>
        <div class="case_item" @click="seeVr(2)">
          <div class="case_image"><img src="https://jimte.oss-cn-hangzhou.aliyuncs.com/static/vr_example_2_7_20.gif" alt=""></div>
          <div class="case_title">杭州湘湖</div>
        </div>
        <div class="case_item" @click="seeVr(3)">
          <div class="case_image"><img src="https://jimte.oss-cn-hangzhou.aliyuncs.com/static/vr_example_3_7_20.gif" alt=""></div>
          <div class="case_title">杭州东方文化园</div>
        </div>
      </div>


      <div class="showMouse" style="display: none;">
        <div class="showMouseIcon"><img src="../../assets/icon/mouse.png" alt=""></div>
        <div class="showMouseIcond"><img src="../../assets/icon/down.png" alt=""></div>
      </div>
    </div>

    <div class="vr_eality_case">

      <div class="product_info_title">
          <div class="product_info_titles">VR功能说明</div>
          <div class="product_info_title_en">VR FUNCTIONAL DESCRIPTION </div>
      </div>
      <div class="vr_eality_case_list">
        <div class="vr_eality_case_type"
        >
          <!--  -->
          <div class="vr_eality_case_type_item" 
          :style="{'border-left':item.show? '2px solid #FF404B':'rgba(219, 219, 219, 0.5)'}" 
          v-for="(item,index) in vrCaseList"
          @mouseenter="handleMouseoverVr(index)"
          :key="index">
            <div class="vr_eality_case_type_item_title"
            :style="{'color':item.show? '#000000':'#9E9E9E'}" 
            >{{item.title}}</div>
            <div :style="{'opacity':item.show? '1':'0'}"  class="vr_eality_case_type_item_dec">{{ item.dec }}</div>
          </div>
       
        </div>
        <div class="vr_eality_case_img"><img  :src="showImg" alt=""></div>
      </div>
    </div>

    <!--  -->
    <div class="productInfo she" style="height: auto;">
      <div class="product_info_title">
          <div class="product_info_titles">VR产品优势</div>
          <div class="product_info_title_en">VR PRODUCT ADVANTAGES </div>
      </div>
      <div class="advantage_list">
        <!--  -->
        <div class="advantage_item" 
        v-for="(item,index) in data"
        @mouseleave="handleMousleave(index)"
        @mouseenter="handleMouseoverBtn(index)"
        :key="index">
          <div class="advantage_icon" >
            <img v-if="!item.active" :src="item.image" alt="">
            <img v-if="item.active" :src="item.imageRed" alt="">
          </div>
          <div class="advantage_item_titles" :style="{'color':item.active? '#FF404B':'#666666'}">{{ item.title }}</div>
          <div class="advantage_name" :style="{'color':item.active? '#FF404B':'#666666'}">{{item.dec}}</div>
        </div>
        <!--  -->

      </div>

      <div class="showMouse" style="display: none;">
        <div class="showMouseIcon"><img src="../../assets/icon/mouse.png" alt=""></div>
        <div class="showMouseIcond"><img src="../../assets/icon/down.png" alt=""></div>
       </div>
    </div>
    <foot />
  </div>
</template>

<script>
import heardNav from '@/components/heardNav/index.vue'
import foot from '@/components/foot/index'
import Hls from 'hls.js';
export default {
  name: 'product',
  props: {
    msg: String
  },
  components: {
    heardNav,
    foot
    },
  data(){
        return{
         
          data:[{
            title:'真实感官' ,
            dec:'真沉浸虚拟景观，体验真实震撼场景。 ',
            image:require('@/assets/zhenshi.png'),
            imageRed:require('@/assets/zhenshi1.png'),
            active:false
          },{
            title:'多样视角' ,
            dec:'多角度观赏，自由调整，体验全面视角。 ',
            image:require('@/assets/duoyang.png'),
            imageRed:require('@/assets/duoyang1.png'),
            active:false
          },{
            title:'高清画质' ,
            dec:'细节高清，旅行感受逼真。 ',
            image:require('@/assets/gaoqing.png'),
            imageRed:require('@/assets/gaoqing1.png'),
            active:false
          },{
            title:'360度全景视频' ,
            dec:'自由环绕，身临其境。',
            image:require('@/assets/360du.png'),
            imageRed:require('@/assets/360du1.png'),
            active:false
          },{
            title:'数字导游' ,
            dec:'增强体验，传达历史文化。',
            image:require('@/assets/shuzi.png'),
            imageRed:require('@/assets/shuzi1.png'),
            active:false
          },{
            title:'全景导航' ,
            dec:'提供详细景点导航和当地特色。',
            image:require('@/assets/quanjing.png'),
            imageRed:require('@/assets/quanjing1.png'),
            active:false
          },{
            title:'社交属性' ,
            dec:'在旅途中互动，建立共同社交圈。',
            image:require('@/assets/shejiao.png'),
            imageRed:require('@/assets/shejiao1.png'),
            active:false
          },{
            title:'扩大市场' ,
            dec:'自扩展市场降低成本，增加游客和收入。',
            image:require('@/assets/kuoda.png'),
            imageRed:require('@/assets/kuoda1.png'),
            active:false
          }],
          vrCaseList:[
            {
              title:"沉浸体验",
              dec:"逼真全景，带您身临其境，探索世界各地的奇观和美景",
              image:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/12/Q6ajsGjYE4.jpg",
              show:true
            },
            {
              title:"智能讲解",
              dec:"虚拟导游，一站式带您了解景区景点",
              image:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/12/Ga7rJQGxWQ.jpg",
              show:false
            },
            {
              title:"云端漫游",
              dec:"探索世界，分享精彩，留下回忆，无需出行即可体验",
              image:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/12/T8Ej7Sfsxk.jpg",
              show:false
            },
            {
              title:"手绘地图",
              dec:"实时数据，形象展示景区地貌和旅游热点",
              image:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/12/MzmGWpZrZm.jpg",
              show:false
            },
            {
              title:"全景导航",
              dec:"一键指引，快速查询，为您的旅游提供贴心导航",
              image:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/12/ktRD2DzSht.jpg",
              show:false
            },],
            showImg:''
        }
  },

  methods:{

  // 按钮移入
  handleMouseoverBtn(e){  
      let that=this
      for (const key in this.data) {
        that.data[key].active=false
      }
      that.data[e].active=true
      
    },
    // 按钮移出
    handleMousleave(e){
      let that=this
      for (const key in this.data) {
        that.data[key].active=false
      }
      
    },
    handleMouseoverVr(e){
      let that=this
      for (const key in that.vrCaseList) {
        that.vrCaseList[key].show=false
      }
      that.vrCaseList[e].show=true
      this.showImg=that.vrCaseList[e].image
    },
    seeVr(e){
      this.$router.push({ name: 'vrInfo', redirect: { name: '/vrInfo' },params:{type:e} });

      localStorage.setItem("num",e)
    },
   
  },
  mounted() {
    let that=this
      for (const key in that.vrCaseList) {
        if (that.vrCaseList[key].show) {
          that.showImg=that.vrCaseList[key].image
        }
      }


      var video = document.getElementById('audioPlayer');
      var videoSrc = 'https://jimeite2.oss-cn-shanghai.aliyuncs.com/80eab0dc431971efbffe5017f1e90102/13964ac9e6af41aca458bd5866296d38.m3u8';
      if (Hls.isSupported()) {
        var video = document.getElementById('audioPlayer');
        var hls = new Hls({
          withCredentials: true
        });
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          console.log('video and hls.js are now bound together !');
        });
        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          console.log(
            'manifest loaded, found ' + data.levels.length + ' quality level',
          );
        });
        hls.loadSource(videoSrc);
        // bind them together
        hls.attachMedia(video);
      }



    },
    

}

</script>

<style scoped lang="stylus">
.banner
  position relative
  .showVideo
    width 100%
    video
      width 100%
      max-height 100%

  .bannerVideo
    position absolute
    width 100%
    height 100%
    display flex
    justify-content center
    align-items center
    z-index 2
    .bannertitle
      font-size 80px
      font-weight 600
      color #fff      
 

.showMouse
    position absolute
    width 100%
    bottom 0
    img
      width 100%
    .showMouseIcon
      width 36px 
      margin 0 auto
    .showMouseIcond
      width 26px
      margin 0 auto

.advantage_list
  display flex
  justify-content space-between
  flex-wrap wrap
  width 1400px
  margin 0 auto
  padding-top 20px
  .advantage_item
    width 324px
    height 324px
    border 1px solid rgba(0, 0, 0, 0.1)  
    margin 34px 0 0 0
    .advantage_icon
      padding-top 50px
      img
        width 64px
        height 64px
    .advantage_item_titles
      color #666666
      font-weight 500
      font-size 34px
      padding  14px 0
    .advantage_name
      width 276px
      text-align center
      font-size 22px
      font-weight 500
      margin 0 auto
.product_info_title
    text-align center
    .product_info_titles
      font-size 42px
      font-weight 600
    .product_info_title_en
      font-size 16px
      font-weight 600
      color rgba(51, 51, 51, 0.5)
      padding-top 16px
.productInfo
  position relative
.first
  padding 54px 0 78px
.case_list
  display flex
  justify-content center
  padding-top 46px
  .case_item
    margin-right 24px
    .case_image
      width 440px
      img
        width 100%
    .case_title
      font-size 34px
      text-align center
      color #666666
.she
  padding 92px 0 154px
.vr_eality_case 
  .vr_eality_case_list
    display flex
    width 1370px
    margin 0 auto
    align-items center
    padding-top 62px
    justify-content space-between
    .vr_eality_case_img
      width 1030px
      height 528px
      img
        width 100%
    .vr_eality_case_type_item
      text-align left
      border-left 2px solid #FF404B
      padding  18px 0 14px 28px
      .vr_eality_case_type_item_title
        font-size 26px
        font-weight 600
      .vr_eality_case_type_item_dec
        color #9E9E9E
        font-size 16px
        width 262px

</style>
