<template>
  <div class="product_main">
    <heardNav  :pageNum="2"></heardNav>
    <div class="banner">
      <div class="bannerVideo">
        <div class="bannertitle">
          遇见臻心生活 追溯旅游诗篇
        </div>
      </div>
      <div class="showVideo"> 
        <video id="audioPlayer" poster=""
          src=""
          autoplay="autoplay" 
          muted
          loop="loop"
          ref="audioPlayer"
            >
          <source src="movie.mp4" type="video/mp4">
          <source src="movie.ogg" type="video/ogg">
        </video> 
      </div>
      <div class="mask_bg"></div>
 
    </div>

    <div class="appInfo">

        <div class="app_info">
          <div class="app_info_img"><img src="../../assets/appImg.png" alt=""></div>
          <div class="app_info_text">
            <div class="app_name">臻心生活APP</div>
            <div class="appline"></div>
            <div class="app_dec">臻心生活是吉美特旗下自主开发自主产权的一款集合短视频、
              直播、消费、全景VR360、文旅、本地生活融为一体的移动应用，是一种新型内容互联网平台，
              融入了新的功能，以全景VR360为基点，实现VR360在公共平台化展示，更便捷更直观，
              全景VR360+文旅+本地生活将是最完美的结合。
              以全景VR360无死角展示全国各地旅游景区、星级酒店、
              民宿、网红餐厅、品牌方、学校、医院、商超、养殖厂、电影院、
              KTV、博物馆、动物园等。以各大场所门票同步平台，更新实时数据为新的创新商业模式。
            </div>
          </div>
        </div>
        <div class="app_bg_img"><img src="../../assets/appbg.png" alt="" >
        </div>
        <div class="app-bg-img1"> <img src="../../assets/icon/dian.png" alt=""></div>
        
    </div>
    <!--  -->
    <div class="productInfo">
      <!--  -->
        <div class="product_info_title">
          <div class="product_info_titles">臻心生活平台生态</div>
          <div class="product_info_title_en">ZHEN XIN SHENG HUO PLATFORM ECOLOGY</div>
        </div>
        <!--  -->
        <div class="product_list">
          <!-- 左边 -->
          <div  class="product_item_left">
            <div class="product_item_info">
              <!--  -->
                <div class="product_item_info_list left1" 
                  @mouseleave="handleMousLeaves(0)"
                  @mouseenter="handleMouseoverBtns(0)">
                  <div class="product_item_info_list_name" :style="{color:productList[0]?'#666666':'#FF404B' }">当地景点</div>
                  <div class="product_item_info_list_icon">
                    <img v-if="productList[0]" src="../../assets/jingdian.png" alt="">
                    <img v-if="!productList[0]" src="../../assets/jingdian1.png" alt="">
                  </div>
                </div>
                <!--  -->
                <div class="product_item_info_list left2"
                  @mouseleave="handleMousLeaves(1)"
                  @mouseenter="handleMouseoverBtns(1)"
                  >
                  <div class="product_item_info_list_name" :style="{color:productList[1]?'#666666':'#FF404B' }">当地政府</div>
                  <div class="product_item_info_list_icon">
                    <img  v-if="productList[1]"src="../../assets/zhengfu.png" alt=""/>
                    
                    <img v-if="!productList[1]" src="../../assets/zhengfu1.png" alt=""/>
                  </div>
                </div>
                <!--  -->
                <div class="product_item_info_list left3"
                  @mouseleave="handleMousLeaves(2)"
                  @mouseenter="handleMouseoverBtns(2)"
                >
                  <div class="product_item_info_list_name" :style="{color:productList[2]?'#666666':'#FF404B' }">当地食宿</div>
                  <div class="product_item_info_list_icon">
                    <img v-if="productList[2]"  src="../../assets/shisu.png" alt="">
                    
                    <img v-if="!productList[2]" src="../../assets/shisu1.png" alt=""/>
                  </div>
                </div>
                <!--  -->
                <div class="product_item_info_list left4"
                @mouseleave="handleMousLeaves(3)"
                @mouseenter="handleMouseoverBtns(3)">
                  <div class="product_item_info_list_name" :style="{color:productList[3]?'#666666':'#FF404B' }">当地特产</div>
                  <div class="product_item_info_list_icon">
                    <img v-if="productList[3]" src="../../assets/dangdi.png" alt="">
                    <img v-if="!productList[3]" src="../../assets/dangdi1.png" alt=""/>
                  </div>
                </div>
                <!--  -->
            </div>
            <!--  -->
            <div class="product_item_bg" style="margin-right: 0;">
              <img src="../../assets/icon/leftBg.png" alt="">
            </div>
          </div>
          <!-- 中间 -->
          <div  class="product_item_center">
            <div class="product_item_center_box" >
                   <!--  -->
                   <div class="product_item_info_list center1"
                    @mouseleave="handleMousLeaves(4)"
                    @mouseenter="handleMouseoverBtns(4)"
                    style="position: absolute;">
                    <div class="product_item_info_list_name" :style="{color:productList[4]?'#666666':'#FF404B' }">媒体矩阵</div>
                    <div class="product_item_info_list_icon">
                      <img v-if="productList[4]"  src="../../assets/meiti.png" alt="">
                      <img v-if="!productList[4]"  src="../../assets/meiti1.png" alt="">
                    </div>
                  </div>
                <!--  -->
                <!--  -->
                <div class="product_item_info_list center2"
                  @mouseleave="handleMousLeaves(5)"
                  @mouseenter="handleMouseoverBtns(5)"
                 style="position: absolute;">
                  <div class="product_item_info_list_name" :style="{color:productList[5]?'#666666':'#FF404B' }">系统培训</div>
                  <div class="product_item_info_list_icon">
                    <img v-if="productList[5]"  src="../../assets/xitong.png" alt="">
                    <img v-if="!productList[5]"  src="../../assets/xitong1.png" alt="">
                  </div>
                </div>
                <!--  -->
                <!--  -->
                <div class="product_item_info_list center3"
                  @mouseleave="handleMousLeaves(6)"
                  @mouseenter="handleMouseoverBtns(6)"
                 style="position: absolute;">
                  <div class="product_item_info_list_icon">
                    <img v-if="productList[6]"  src="../../assets/liuliang.png" alt="">
                    <img v-if="!productList[6]"  src="../../assets/liuliang1.png" alt="">
                  </div>
                  <div class="product_item_info_list_name" :style="{color:productList[6]?'#666666':'#FF404B' }">流量扶持</div>
                  
                </div>
                <!--  -->
                 <!--  -->
                 <div class="product_item_info_list center4"
                  @mouseleave="handleMousLeaves(7)"
                  @mouseenter="handleMouseoverBtns(7)" 
                 style="position: absolute;">
                  <div class="product_item_info_list_icon">
                    <img  v-if="productList[7]"  src="../../assets/keji.png" alt="">
                    <img  v-if="!productList[7]"  src="../../assets/keji1.png" alt="">
                  </div>
                  <div class="product_item_info_list_name" :style="{color:productList[7]?'#666666':'#FF404B' }">科技支持</div>
                </div>
                <!--  -->
            </div>
            <div class="product_item_center_icons">
                <div class="product_item_center_icon">
                  <img src="../../assets/icon/centerIcon.png" alt="">
                  <div class="product_item_center_logo">
                    <img src="../../assets/zhenxin.png" alt="" srcset="">
                  </div>
                </div>
            </div>
          </div>
          <!-- 右边部分 -->
          <div  class="product_item_right">
            <div class="product_item_info">
              <!--  -->
                <div class="product_item_info_list right1"
                  @mouseleave="handleMousLeaves(8)"
                  @mouseenter="handleMouseoverBtns(8)"
                >
                  <div class="product_item_info_list_icon">
                    <img v-if="productList[8]"  src="../../assets/jishu.png" alt="">
                    <img v-if="!productList[8]"  src="../../assets/jishu1.png" alt="">
                  </div>
                  <div class="product_item_info_list_name" :style="{color:productList[8]?'#666666':'#FF404B' }">技术创新</div>
                  
                </div>
                <!--  -->
                <div class="product_item_info_list right2"
                  @mouseleave="handleMousLeaves(9)"
                  @mouseenter="handleMouseoverBtns(9)"
                >
                  <div class="product_item_info_list_icon">
                    <img v-if="productList[9]"  src="../../assets/rencai.png" alt="">
                    <img v-if="!productList[9]"  src="../../assets/rencai1.png" alt="">
                  </div>
                  <div class="product_item_info_list_name" :style="{color:productList[9]?'#666666':'#FF404B' }">人才培养</div>
                </div>
                <!--  -->
                <!--  -->
                <div class="product_item_info_list right3"
                  @mouseleave="handleMousLeaves(10)"
                  @mouseenter="handleMouseoverBtns(10)"
                >
                  <div class="product_item_info_list_icon">
                    <img v-if="productList[10]"  src="../../assets/zhanlue.png" alt="">
                    <img v-if="!productList[10]"  src="../../assets/zhanlue1.png" alt="">
                  </div>
                  <div class="product_item_info_list_name" :style="{color:productList[10]?'#666666':'#FF404B' }">战略联盟</div>
                </div>
                <!--  -->
                <div class="product_item_info_list right3" style="opacity: 0;">
                  <div  class="product_item_info_list_icon"></div>
                  <div class="product_item_info_list_name" >战略联盟</div>
                </div>
            
            </div>
            <!--  -->
            <div class="product_item_bg" style="margin-left: 0;">
              <img src="../../assets/icon/rightBg.png" alt="">
            </div>
          </div>
        </div>
    </div>
    <!--  -->
    <div class="productInfo she" style="height: auto;">
      <div class="product_info_title">
          <div class="product_info_titles">臻心生活产品优势</div>
          <div class="product_info_title_en">ZHEN XIN SHENG HUO PRODUCT ADVANTAGE</div>
      </div>
      <div class="advantage_list">

        <!--  -->
        <div class="advantage_item" 
        v-for="(item,index) in data"
        @mouseleave="handleMousleave(index)"
        @mouseenter="handleMouseoverBtn(index)"
        :key="index">
          <div class="advantage_icon" >
            <img v-if="!item.active" :src="item.image" alt="">
            <img v-if="item.active" :src="item.imageRed" alt="">
          </div>
          <div class="advantage_name" :style="{'color':item.active? '#FF404B':'#666666'}">{{item.title}}</div>
        </div>
        <!--  -->

      </div>

      <div class="showMouse">
        <div class="showMouseIcon"><img src="../../assets/icon/mouse.png" alt=""></div>
        <div class="showMouseIcond"><img src="../../assets/icon/down.png" alt=""></div>
       </div>
    </div>
    <foot />
    <!--  -->
  </div>
</template>

<script>

import heardNav from '@/components/heardNav/index.vue'
import foot from '@/components/foot/index'
import Hls from 'hls.js';
export default {
  name: 'product',
  props: {
    msg: String
  },
  components: {
    heardNav,
    foot

    },
  data(){
        return{
          productList:[
          true,true,true,true,true,true,true,true,true,true,true,
          // false,false,false,false,false,false,false,false,false,false,
          ],
          data:[{
            title:'VR全景' ,
            image:require('@/assets/vr.png'),
            imageRed:require('@/assets/vr1.png'),
            active:false
          },{
            title:'智能导航导览' ,
            image:require('@/assets/zhineng.png'),
            imageRed:require('@/assets/zhineng1.png'),
            active:false
          },{
            title:'在线预订' ,
            image:require('@/assets/zaixian.png'),
            imageRed:require('@/assets/zaixian1.png'),
            active:false
          },{
            title:'社区攻略游记' ,
            image:require('@/assets/shequ.png'),
            imageRed:require('@/assets/shequ1.png'),
            active:false
          },{
            title:'个性路线规划' ,
            image:require('@/assets/gexing.png'),
            imageRed:require('@/assets/gexing1.png'),
            active:false
          },{
            title:'本地生活推荐' ,
            image:require('@/assets/bendi.png'),
            imageRed:require('@/assets/bendi1.png'),
            active:false
          },{
            title:'旅行社区组团' ,
            image:require('@/assets/shetuan.png'),
            imageRed:require('@/assets/shetuan1.png'),
            active:false
          },{
            title:'AI智能客服' ,
            image:require('@/assets/ai.png'),
            imageRed:require('@/assets/ai1.png'),
            active:false
          },{
            title:'达人KOL推荐' ,
            image:require('@/assets/daren.png'),
            imageRed:require('@/assets/daren1.png'),
            active:false
          },{
            title:'特色特产' ,
            image:require('@/assets/tese.png'),
            imageRed:require('@/assets/tese1.png'),
            active:false
          },]
         
        }
  },

  methods:{

    // 按钮移入
    handleMouseoverBtn(e){  
      let that=this
      for (const key in this.data) {
        that.data[key].active=false
      }
      that.data[e].active=true
      
    },
    // 按钮移出
    handleMousleave(e){
      let that=this
      for (const key in this.data) {
        that.data[key].active=false
      }
    },
    


     // 按钮移chu————————————————————产品介绍
     handleMousLeaves(){  
      console.log('chu')
      let that=this
      for (const key in this.productList) {
        that.productList[key]=true
      }
      this.$forceUpdate()
      
    },
    // 按钮移ru——————————————————————产品介绍
    handleMouseoverBtns(e){
      console.log('ru',e)
      let that=this
      for (const key in this.productList) {
        that.productList[key]=true
      }
      that.productList[e]=false
      this.$forceUpdate()
      console.log(that.productList)
    },
   
  },
  mounted() {

    var video = document.getElementById('audioPlayer');
      var videoSrc = 'https://jimeite2.oss-cn-shanghai.aliyuncs.com/80cee8c043e171ef806c4531859c0102/cca150fa5104410aa7c3dfb07d02e304.m3u8';
      if (Hls.isSupported()) {
        var video = document.getElementById('audioPlayer');
        var hls = new Hls({
          withCredentials: true
        });
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          console.log('video and hls.js are now bound together !');
        });
        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          console.log(
            'manifest loaded, found ' + data.levels.length + ' quality level',
          );
        });
        hls.loadSource(videoSrc);
        // bind them together
        hls.attachMedia(video);
      }
     
  },

}

</script>

<style scoped lang="stylus">
.banner
  position relative
  .showVideo
    width 100%
    video
      width 100%
      max-height 100%
      
  .bannerVideo
    position absolute
    width 100%
    height 100%
    display flex
    justify-content center
    align-items center
    .bannertitle
      font-size 80px
      font-weight 600
      color #fff      
      position relative
      z-index 2
 
 
.appInfo
    position relative
    padding-bottom 58px
    .app_bg_img
      width 1600px
      position absolute
      top 274px
      z-index 2
      left 0
      img
        width 100%
    .app-bg-img1
      width 720px
      position absolute
      top 0
      right 0
      z-index 1
      height 600px
      overflow hidden
      img
        width 100%
        
    .app_info
        display flex
        justify-content center
        align-items center
        position relative
        z-index 3
        padding-top 80px
      .app_name
        font-size 38px
        text-align left
        color #FFF
      .app_info_img
        width 348px
        img
          width 100%
      .app_info_text
        padding-left 28px
        .app_dec
          width 796px
          font-size 14px
          text-align left
          color #fff
          line-height 22px
        .appline
          width: 130px;
          height: 6px;
          margin-top 28px
          margin-bottom 38px
          background #fff

.productInfo
  height 900px
  margin-top 10px
  .product_info_title
    text-align center
    .product_info_titles
      font-size 42px
      font-weight 600
    .product_info_title_en
      font-size 16px
      font-weight 600
      color rgba(51, 51, 51, 0.5)
      padding-top 16px
  .product_list
    width 1384px
    margin 0 auto
    display flex
    justify-content center
    padding 36px 0 40px
    align-items center
    .product_item_left
      position relative
      .product_item_bg
        width 144px
        height 560px
        position absolute
        right 0
        top 0
        img
         max-height 100%
         max-width  100%
          
    .product_item_center
      position relative
      width 620px
      .product_item_center_icons
        width 100%
        position absolute
        left 0
        top 0
        .product_item_center_icon
          width 460px
          height 460px
          margin 0 auto
          position relative
          img
            width 100%
            height 100%
    .product_item_right
      position relative
      .product_item_bg
        width 144px
        height 560px
        position absolute
        left 0
        top 0
    
        img
         max-height 100%
         max-width  100%
  .product_item_info_list
    display flex
    align-items center
    margin-bottom 70px
    position relative
    
    .product_item_info_list_icon
      width 72px
      height 72px
      box-sizing border-box
      overflow hidden
      box-shadow: 0px 8px 14px 0px rgba(174, 174, 178, 0.35);
      border-radius 50%
      background #fff
      padding 16px
      img
        max-width 100%
        height 100%
    .product_item_info_list_name
      font-size 26px
      color #666
      padding 0 8px
      font-weight 600
      
.left1
  left -10px
.left2
  left -78px
.left3
  left -88px
.left4
  left -38px
  bottom -20px
.center1
  left 0
  top 38px
.center2
  left -10px
  bottom -20px
.center3
  right 0
  top 38px
.center4
  right 0px
  bottom  -20px
.product_item_center_box
  position relative
  height 460px
  z-index 1
.right1
  top 88px
  right -58px

 .right2
  top 98px
  right -88px

.right3
  bottom -110px
  right -60px
.product_item_info
  position relative
  z-index 1
.product_item_center_logo
  position absolute
  left 0
  top 0
  display flex
  align-items center
  justify-content center
  width 100%
  height 100%
  img
    width 156px !important
    height 156px !important

.advantage_list
  display flex
  justify-content space-between
  flex-wrap wrap
  width 1400px
  margin 0 auto
  .advantage_item
    width 250px
    height 250px
    border 1px solid rgba(0, 0, 0, 0.1)  
    margin 34px 0 0 0
    .advantage_icon
      padding-top 50px
      img
        width 64px
        height 64px
        margin 0 auto 48px
    .advantage_name
      text-align center
      font-size 28px
      font-weight 400
.she
  padding-bottom 104px
.showMouse
    position absolute
    width 100%
    bottom 0
    display none 
    img
      width 100%
    .showMouseIcon
      width 36px 
      margin 0 auto
    .showMouseIcond
      width 26px
      margin 0 auto

</style>
