<template>
  <div class="main">
    <div class="swiper">
  <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide">
          <div class="showBanner"><img src="../../assets/banner2.png" alt="" srcset=""> </div>
        </div>
        <div class="swiper-slide">
          <div class="showBanner"> <img src="../../assets/banner1.png" alt="" srcset=""></div>
        </div>
        <div class="swiper-slide">
          <div class="showBanner" @click="goVr"> <img src="../../assets/banner3.png" alt="" srcset=""></div>
        </div>
      </div>
      <div class="swiper-pagination"></div>

    </div>
     <!--  swiper ------ end-->
      <div class="product">
        <div class="product_concent">

          <div class="product_img">
            <img src="../../assets/product.png" alt="">
          </div>
          <!--  -->
          <div class="product_dec">
            <div class="product_dec_title">
              <div class="product_dec_title_name">臻心生活</div>
              <div class="product_dec_title_en">ZHEN XIN SHENG HUO</div>
            </div>
            <div class="product_dec_concent">
              <div class="product_dec_dec">
                臻心生活上是一个将文化资源（如文化场馆、博物馆、艺术表演、美食、传统手工艺等）与旅游服务（如旅游景区、酒店、演出、餐饮、交通等）相连接的综合性平台。
                这种平台不仅是一个旅游产品的销售平台，更是一个提供全方位文化体验的综合平台。文旅一体化平台的出现，标志着文化和旅游产业正朝着更深度、更广泛的融合方向发展。
              </div>
              <!--  -->
              <div class="product_dec_list">
                <div class="product_dec_item" v-for="(item,index) in decList" :key="index"> 
                  <div class="product_dec_item_show" 
                  @mouseenter.stop="handleMouseover(index)"
                  v-if="!item.active" 
                  :style="{'background-image':'url('+item.showUrl+')'}">
                    {{item.title}}
                  </div>
                  <div class="product_dec_item_hid"
                  :style="{'background-image':'url('+item.hidUrl+')'}"
                   v-if="item.active" >
                    <div class="product_dec_item_hid_title">{{item.title}}</div>
                    <div class="product_dec_item_hid_dec">{{ item.dec }}</div>
                    <div class="product_dec_item_hid_btn"  
                    @mouseleave="handleMousleave(index)"
                    @mouseenter="handleMouseoverBtn(index)"
                    @click="goApp"
                    :style="{'background':item.showActive? 'rgba(255, 255, 255, 0.4)':'#FF404B'}"
                    >查看详情  <img  src="../../assets/righticon.png" alt=""></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- 分公司 -->
      <div class="companyInfo">
        <div class="company_info">
          <div class="company_left_text">
            <div class="company_left_tips">关于我们</div>
            <div class="company_left_title">文旅全球服务生态平台</div>
            <div class="company_left_dec">公司依托科技与大数据全面结合国内当下热门趋势VR+文旅，
              全力打造国内首家 VR360、 VR720+文旅一站式服务，
              一部手机利用我们的APP即可提前做好各大景区攻略，未达先知，
              了解景区详情，当地特产，本地生活服务等，公司致力于打造为民服务便捷，
              利国利民的新型互联网平台，立志为国为民创造价值，成为国内外新型文旅生态行业引领者。 </div>
              <div class="company_left_data">
                <div class="company_left_data_item">
                  <div class="company_data_item_num">238</div>
                  <div class="company_data_item_name">合作供应商</div>
                </div>
                <!--  -->
                <div class="company_left_data_item">
                  <div class="company_data_item_num">30</div>
                  <div class="company_data_item_name">集团矩阵</div>
                </div>
                <!--  -->
                <div class="company_left_data_item" style="padding-right:0;">
                  <div class="company_data_item_num">22</div>
                  <div class="company_data_item_name">业务遍及地区</div>
                </div>
              </div>
          </div>
          <div class="company_right_img">
            <img src="https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/08/iDNcAchjXH.png" alt="">
          </div>
        </div>
        <!-- <div class=" company_swiper">
          <div class="myswiper"> -->
        <!-- Additional required wrapper -->
            <!-- <div class="swiper-wrapper"> -->
              <!-- Slides -->
              <!-- <div class="swiper-slide" 
              style="width: auto;"
              v-for="(item,index) in companyList"
               :key="index">
                <div class="company_item"><img :src="item.url" alt="" srcset=""> </div>
              </div>
             -->
            <!-- </div> -->
          <!-- </div>
        </div> -->
      </div>
      
      <div class="idea">
          <div class="idea_title">
              <div class="idea_title_name">企业文化与理念</div>
              <div class="idea_title_en">CORPORATE CULTURE AND PHILOSOPHY</div>
          </div>
          <div class="idea_list">
            <div class="idea_item">
              <div class="idea_item_icon"><img src="../../assets/icon/idea1.png" alt=""></div>
              <div class="idea_item_title">企业使命</div>
              <div class="idea_item_dec">致力于提供优质的文旅/生活服务，提升人们的生活品质。</div>
            </div>
            <div class="idea_item">
              <div class="idea_item_icon"><img src="../../assets/icon/idea2.png" alt=""></div>
              <div class="idea_item_title">核心价值观</div>
              <div class="idea_item_dec">以客户为中心，注重创新，追求卓越，注重团队合作。</div>
            </div>
            <div class="idea_item">
              <div class="idea_item_icon"><img src="../../assets/icon/idea3.png" alt=""></div>
              <div class="idea_item_title">企业愿景</div>
              <div class="idea_item_dec">成为全球领先的文旅/生活服务提供商，引领行业发展。</div>
            </div>
          </div>
          <div class="idea_swiper">
            <div class="idea_title">
              <div class="idea_title_cn">企业荣誉</div>
              <div class="idea_title_en">ENTERPRISE HONOR</div>
            </div>
            <div class="idea_swiper_list">


                <div class="myswiper1">
              <!-- Additional required wrapper -->
                  <div class="swiper-wrapper">
                    <!-- Slides -->
                    <div class="swiper-slide" 
                    v-for="(item,index) in certificateList" 
                    :key="index">
                      <div class="company_item"><img :src="item.image" alt="" srcset=""> </div>
                    </div>
                  
                  </div>
                </div>


            </div>
          </div>
      </div>


  </div>
</template>

<script>
import Swiper from 'swiper';
import autoplay from 'swiper/modules/autoplay/autoplay';
import pagination from 'swiper/modules/pagination/pagination';
import 'swiper/swiper.min.css'
import 'swiper/modules/autoplay/autoplay.min.css'
import 'swiper/modules/pagination/pagination.min.css'
export default {
  // components: {
  //     SwiperSlide,
  //   },
  data(){
        return{
          decList:[
            {
              title:"游客角度",
              dec:"为游客提供便利、个性化的旅游体验。通过智能推荐、VR沉浸式体验、实时信息服务、在线预订和及相关餐饮住宿等功能，使游客的旅游体验更加便捷和高效。",
              active:true,
              showUrl:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/08/4ZEymA3Rnz.png",
              hidUrl:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/08/85A7TwbWjR.png",
              showActive:true
            },
            {
              title:"景区角度",
              dec:"实现高效、智能的运营管理。通过数据驱动的运营决策、智能化游客管理、数字化营销与推广等功能，帮助景区提升管理效率和服务质量。",
              active:false,
              showUrl:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/08/BHD6aEpt3b.png",
              hidUrl:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/08/D4REm7ZSWW.png",
              showActive:true
            },
            {
              title:"文化服务标准化",
              dec:"将各类文化机构、景区，餐饮住宿，文化活动及旅游相关企业纳入统一的框架，打破了信息壁垒，提供了全方位的文化服务。",
              active:false,
              showUrl:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/08/WsGZ2b3FH5.png",
              hidUrl:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/08/eNABPHyxQZ.png",
              showActive:true
            },
          ],
          companyList:[{
            name:"杭州吉美特",
            url:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/08/6id6wC3722.png",
          },{
            name:"杭州吉美特",
            url:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/08/6id6wC3722.png"
          },{
            name:"杭州吉美特",
            url:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/08/6id6wC3722.png"
          },{
            name:"杭州吉美特",
            url:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/08/6id6wC3722.png"
          },{
            name:"杭州吉美特",
            url:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/08/6id6wC3722.png"
          },{
            name:"杭州吉美特",
            url:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/08/6id6wC3722.png"
          },{
            name:"杭州吉美特",
            url:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/08/6id6wC3722.png"
          },{
            name:"杭州吉美特",
            url:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/08/6id6wC3722.png"
          },{
            name:"杭州吉美特",
            url:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/08/6id6wC3722.png"
          },{
            name:"杭州吉美特",
            url:"https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/08/6id6wC3722.png"
          },],

          certificateList:[{
            name:"杭州吉美特",
            image:require('@/assets/certificate.png'),
          },{
            name:"杭州吉美特",
            image:require('@/assets/certificate1.png'),
          },{
            name:"杭州吉美特",
            image:require('@/assets/certificate2.png'),
          },{
            name:"杭州吉美特",
            image:require('@/assets/certificate3.png'),
          },{
            name:"杭州吉美特",
            image:require('@/assets/certificate4.png'),
          },{
            name:"杭州吉美特",
            image:require('@/assets/certificate5.png'),
          },{
            name:"杭州吉美特",
            image:require('@/assets/certificate6.png'),
          },{
            name:"杭州吉美特",
            image:require('@/assets/certificate7.png'),
          },{
            name:"杭州吉美特",
            image:require('@/assets/certificate8.png'),
          },{
            name:"杭州吉美特",
            image:require('@/assets/certificate9.png'),
          },{
            name:"杭州吉美特",
            image:require('@/assets/certificate10.png'),
          },],

        }
  },
  
  methods:{
    setSwiper(){
     let swiper= new Swiper('.swiper', {
      autoHeight: true, 
      modules: [autoplay,pagination],
      autoplay: {
      delay: 5000,
      disableOnInteraction: false,
      
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      loop:true,
        on:{
      
      }

      })
     let swipers= new Swiper('.myswiper', {

      slidesPerView: 6,
      spaceBetween: 30,
      modules: [autoplay],
      speed:2000,
      autoplay: {
      delay: 1000,
      disableOnInteraction:false,
      pauseOnMouseEnter:true,
      reverseDirection:true,
      },
      loop:true,
        on:{
      
      }
      })

      swiper= new Swiper('.myswiper1', {
        slidesPerView: 6,
        spaceBetween: 30,
   
        modules: [autoplay],
        speed:2000,
        autoplay: {
          delay: 1000,
          disableOnInteraction:false,
          pauseOnMouseEnter:true,
          reverseDirection:true
        },
        loop:true,
          on:{

        }
        })
    },
    handleMouseover(e){
      let that=this
      for (const key in this.decList) {
        that.decList[key].active=false
      }
      that.decList[e].active=true
    },
    // 按钮移入
    handleMouseoverBtn(e){  
      console.log(e)
      let that=this
      for (const key in this.decList) {
        that.decList[key].showActive=true
      }
      that.decList[e].showActive=false
      
    },
    // 按钮移出
    handleMousleave(e){
  
      this.decList[e].showActive=true
      
    },
    goApp(){
      this.$router.push({ name: 'product', });
    },
    goVr(){
      this.$router.push({ name: 'vr', });
    },
   
  },
  mounted() {
     this.setSwiper()
     
    },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.swiper
  width 100%
  img
    width 100%

.product
  .product_concent
    display flex
    justify-content space-between
    padding-top 54px
    .product_img
      width 630px
      img
        width 100%
    .product_dec
      width 1250px
      .product_dec_title
        padding-left 100px
        font-size 42px
        font-weight 600
        text-align left
        .product_dec_title_name
          padding-left 4px
        .product_dec_title_en
          color: rgba(51, 51, 51, 0.50)
          font-size: 16px
          font-weight: 600
          padding 20px 0
      .product_dec_dec
        padding 80px 40px
        background url('../../assets/textbg.png')
        background-size 100%
        color #fff
        text-align left
        font-size 16px
        letter-spacing 2px
        line-height 22px
        padding-right 230px
        width 100%
        box-sizing border-box
 
    .product_dec_list
      display flex
      position relative
      top -32px
      .product_dec_item
        padding-right 28px
        .product_dec_item_show
          width 238px
          padding-top 32px
          background-size 100%
          height 400px   
          color #000000
          font-weight 600
          font-size 18px
          box-sizing border-box
        .product_dec_item_hid
          color #fff
          padding 30px
          width  452px
          height 400px
          background-size 100%
          box-sizing border-box
          .product_dec_item_hid_title
              font-weight 600
              font-size 20px
              padding 26px 0 38px
              border-bottom 1px solid #fff
              text-align left
          .product_dec_item_hid_dec
            width 390px
            font-size 16px
            word-break normal
            line-height 26px 
            text-align left
            padding 38px 0 44px
            box-sizing border-box
          .product_dec_item_hid_btn
            background #FF404B
            font-size 18px
            width 200px
            height  48px
            line-height 48px 
            color #fff
            margin-left 0
            img
              width 30px
              vertical-align: middle;
.product_dec_concent
  padding-left 30px
.companyInfo
  position relative
  .company_swiper
      width 100%
      overflow hidden
      position absolute
      bottom  60px
      .company_item
 
        height 230px
        overflow hidden
        img
          width 100%
.companyInfo
    .company_info
      display flex
      justify-content space-between
      .company_right_img
        width 800px
        img
          width 100%
          height 100%
      .company_left_text
        flex 1
        padding-left 172px
        .company_left_tips
          color #FF404B
          font-weight 600
          font-size 16px
          text-align left
          padding-top 204px
        .company_left_title
            font-size: 42px;
            font-weight: 700;
            text-align: left;
            padding-top 30px
        .company_left_dec
          width 750px
          padding-top 80px
          text-align left
        .company_left_data
          display flex
          justify-content space-between
          width 100%
          padding 24px 105px
          box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.04);
          margin-top 100px
          box-sizing  border-box
          .company_data_item_num
            color: #FF404B;
            font-size: 42px;
            font-weight:800;
            line-height: 34px; /* 94.118% */
          .company_left_data_item
              text-align left
              margin-right 80px
            .company_data_item_name
              font-size  16px
              padding-top 8px 
          

.idea
  background-image url('https://jimte.oss-cn-hangzhou.aliyuncs.com/crmebimage/public/product/2024/07/09/JaDAmFTcCS.png')
  background-size 100%
  padding 60px 0 150px
  .idea_title
    text-align center
    .idea_title_name
      text-align center
      font-size 50px
      font-weight 600
    .idea_title_en
      font-size 18px
      font-weight 600
      color rgba(51, 51, 51, 0.5)
      padding-top 10px
  .idea_list
    width 1258px
    margin 0 auto
    display flex
    justify-content space-between
    padding-top 52px
    .idea_item
      text-align center
      .idea_item_icon
        width  104px
        height 104px
        margin 0 auto
        overflow hidden
        img
          width 100%
          height 100%
      .idea_item_title
        padding 32px 0 20px
        font-size 38px
        font-weight 600
      .idea_item_dec
        font-size 18px
        font-weight 600
        width 342px
  .idea_swiper
      .idea_title
        padding 72px 0 32px
        text-align center
      .idea_title_cn
        font-size 50px
      .idea_title_en
        padding 20px 0 34px
        font-size 18px
      .company_item
        height 264px
        img
 
          max-height 100%
      .myswiper1
        width 100%
        overflow hidden

.myswiper1
  .swiper-wrapper
    .swiper-slide
      width: auto !important;
.swiper
  // --swiper-theme-color: #fff;
  --swiper-pagination-color: #fff;/* 两种都可以 */
  .swiper-pagination 
    bottom 24px !important
</style>
