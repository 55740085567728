<template>
  <div class="mobileMain">
    <div class="heardNav">
        <div class="leftIcon" @click="show=true"><img src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/moreIcon.png" alt=""></div>
        <div class="rightName">重交智安(浙江)科技发展有限公司</div>
    </div>
    <div class="productList">
        <div class="videoOrImg">
            <div class="showTitleName">新媒体运营</div>
            <div class="showTitleTwo">拍摄 / 策划 /宣传 / 演练</div>
            <div class="showImgList">
                <div class="showImgItem" @click="setShowVideo(index+1)" v-for="(item,index) in showImgList" :key="index">
                    <div class="showImgUrl"><img :src="item.url" alt=""></div>
                    <div class="showName">{{item.name}}</div>
                </div>
            </div>
        </div>
    </div>
    <van-popup v-model="show" position="left" :style="{ height: '100%' }">
       <div class="showLeftPupop">
        <div class="showLeftPupopItem " @click="getUrl(0)">首页</div>
        <div class="showLeftPupopItem " @click="getUrl(1)">产品</div>
        <div class="showLeftPupopItem actives" >新媒体</div>
        <div class="showLeftPupopItem " @click="getUrl(3)">渠道加盟</div>
        <div class="showLeftPupopItem " @click="getUrl(4)">关于我们</div>
       </div>
    </van-popup>

      <van-popup v-model="showVide" closeable position="center"  >
       <div class="showLeftPupop">
          <div class="showVider" v-if="showItem==1">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/%E6%88%90%E5%93%81%C2%B7%E9%83%BD%E5%B7%B4%E9%AB%98%E9%80%9F%E5%85%AC%E8%B7%AF-%EF%BC%88%E4%B8%B2%E8%81%94%E7%9A%84%E5%89%AA%E8%BE%91%EF%BC%89.mp4"
                autoplay="autoplay" 
                controls
                muted
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>
          
          <div class="showVider" v-if="showItem==2">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/%E5%B4%87%E5%87%AD%E5%BA%94%E6%80%A5%E6%BC%94%E7%BB%83%EF%BC%88%E6%95%91%E6%8F%B4%E5%8F%8A%E7%82%B9%E8%AF%84%EF%BC%89.mp4"
                autoplay="autoplay" 
                muted
                controls
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>

          <div class="showVider" v-if="showItem==3">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/%E5%B9%BF%E8%A5%BF%E6%A1%82%E6%9F%B3%E6%B6%88%E9%98%B2%E5%BA%94%E6%80%A5%E6%BC%94%E7%BB%83%EF%BC%88%E5%85%A8%E7%A8%8B%EF%BC%89_1.mp4"
                autoplay="autoplay" 
                muted
                controls
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>


          <div class="showVider" v-if="showItem==4">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/%E6%96%B0%E6%9F%B3%E5%8D%97%E5%B9%B3%E5%AE%89%E5%B7%A5%E7%A8%8B%E7%94%B3%E6%8A%A5-1024-3_1.mp4"
                autoplay="autoplay" 
                muted
                controls
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>


          <div class="showVider" v-if="showItem==5">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/%E4%B9%90%E4%B8%9A%E8%87%B3%E6%9C%9B%E8%B0%9F%E9%AB%98%E9%80%9F%E5%85%AC%E8%B7%AF%E9%9A%A7%E9%81%93%E5%9D%8D%E5%A1%8C%E5%BA%94%E6%80%A5%E6%95%91%E6%8F%B4%E6%BC%94%E7%BB%83.mp4"
                autoplay="autoplay" 
                muted
                controls
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>

          <div class="showVider" v-if="showItem==6">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/2021%E6%9D%AD%E5%B7%9E%E4%B8%B4%E5%BB%BA%E9%AB%98%E9%80%9F%E5%85%AC%E8%B7%AF%E5%BB%BA%E8%AE%BE%E9%A1%B9%E7%9B%AE%E7%9B%B4%E6%92%AD4_1.mp4"
                autoplay="autoplay" 
                muted
                controls
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>

          <div class="showVider" v-if="showItem==7">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/%E6%BC%94%E7%BB%83%E6%B4%BB%E5%8A%A8%E9%83%A8%E5%88%86_1.mp4"
                autoplay="autoplay" 
                muted
                controls
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>


          <div class="showVider" v-if="showItem==8">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/%E5%B9%B3%E5%A4%A9%E5%BA%94%E6%80%A5%E6%BC%94%E7%BB%83%E5%89%AA%E8%BE%91%E6%88%90%E5%93%81%E8%A7%86%E9%A2%91.mp4"
                autoplay="autoplay" 
                muted
                controls
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>


          <div class="showVider" v-if="showItem==9">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/%E4%BB%99%E5%B1%85%E7%8E%B0%E5%9C%BA%E4%BC%9A.mp4"
                autoplay="autoplay" 
                muted
                controls
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>

          <div class="showVider" v-if="showItem==10">
              <video id="audioPlayer" poster="img/poster.7e25c47b.png"
                src="https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/video/8.18%20%E8%AF%B8%E6%9A%A8235%E5%9B%BD%E9%81%93.mp4"
                autoplay="autoplay" 
                muted
                controls
                loop="loop"
                ref="audioPlayer"
                  >
                <source src="movie.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
              </video>
          </div>


       </div>
    </van-popup>
  </div>
</template>

<script>
// var _czc = _czc || [];
// //绑定siteid，请用您的siteid替换下方"XXXXXXXX"部分
// _czc.push(["_setAccount", "1280801298"]);
export default {
  name: 'pageindex',
  props: {
    msg: String
  },
  data(){
        return{
            pupop:false,
            volume:0,
            show:false,
            showVideoStatus:false,
            showVide:false,
            showItem:0,
            showImgList:[{
                name:"都巴高速平安工程",
                url:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/1.png'
            },{
                name:"广西崇凭高速公路",
                url:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/2.png'
            },{
                name:"广西桂柳消防应急演练",
                url:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/3.png'
            },{
                name:"广西新柳南平安工程",
                url:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/4.png'
            },{
                name:"乐望应急演练",
                url:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/5.png'
            },{
                name:"临建三防应急演练",
                url:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/6.png'
            },{
                name:"平湖应急演练",
                url:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/7.png'
            },{
                name:"天平项目隧道",
                url:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/8.png'
            },{
                name:"仙居现场会宣传片",
                url:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/9.png'
            },{
                name:"诸暨坍塌应急演练",
                url:'https://anzhique.oss-cn-hangzhou.aliyuncs.com/2022315/website/10.png'
            },]
        }
  },

  methods:{
    showVideo(){
      this.showVideoStatus=!this.showVideoStatus
    },
    getUrl(e){
      if(e==0){
        this.$router.push({name:'Home'})
      }
      if(e==1){
        this.$router.push({name:'m_product'})
      }
      if(e==2){
        this.$router.push({name:'m_video'})
      }
      if(e==3){
        this.$router.push({name:'m_join'})
      }
      if(e==4){
        this.$router.push({name:'m_aboutus'})
      }
    },
    setShowVideo(e){
      this.showVide=true
      this.showItem=e
    }
  }
}

</script>

<style scoped lang="stylus">
.heardNav
  display flex
  justify-content flex-start
  align-items center
  padding 15px 25px
  position absolute
  top 0
  left: 0
  background #fff
  width 100%
  box-sizing  border-box
  z-index 99
  .leftIcon
    padding-right 24px
    img
      width 20px
      height  20px
      vertical-align: middle
  .rightName
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    letter-spacing: 2px;

.productList
    padding 60px 20px   60px
    width   100%
    box-sizing: border-box;
    overflow hidden
    text-align  left

    img
        width   100%
.showTitleName
    font-size: 24px;
    color: #333333;
    font-weight 600

.showTitleTwo
    font-size: 16px;
    color: #333333;
    padding-top 5px
    font-weight 600
.showImgList
    display flex
    justify-content: space-between
    text-align  left
    flex-wrap: wrap;
.showImgItem
    padding-top 20px
    .showImgUrl
        width: 165px;
        height: 165px;
.showName
    font-size: 14px;
    color: #333333;
    font-weight 600
    padding-top 10px
  
.showLeftPupop
  max-width 400px
  min-width 350px
  margin: 0 auto;
  padding-top 34px
.showVider
  width 90%
  padding: 20px 0 40px
  margin: 0 auto;
  display:flex;
  align-items: center
  video
    width:100%;
</style>
